import { render, staticRenderFns } from "./TemplateSettingsPresets.vue?vue&type=template&id=a407aec8&scoped=true&"
import script from "./TemplateSettingsPresets.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TemplateSettingsPresets.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TemplateSettingsPresets.vue?vue&type=style&index=0&id=a407aec8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a407aec8",
  null
  
)

export default component.exports